import * as React from "react";
import { List, TextField, Datagrid, SimpleList } from "react-admin";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';



const CmsBlockList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* {renderCmsPageImage(record)} */}
        <div><b>Title:</b> {record.title}</div>
        <div><b>Location:</b> {record.location}</div>
        {/* <div><b>Image:</b> {record.image}</div> */}
      </div>
    )
  }

  return (
    <List {...props} exporter={false} perPage={50}>
      {isSmall ? (
        <SimpleList
          primaryText={record => <div><b>ID:</b> {record.id}</div>}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="location" />
          <TextField source="title" />

          {/* <FunctionField
            label="Image"
            render={renderCmsPageImage}
          /> */}
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(CmsBlockList, 'CMS Block List');
