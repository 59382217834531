import { MenuItemLink, useDataProvider } from "react-admin";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import BookIcon from "@mui/icons-material/Book";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BorderAllIcon from '@mui/icons-material/BorderAll';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import styled from "styled-components";
import EventIcon from '@mui/icons-material/Event';

const StyledMenuItemLink = styled(MenuItemLink)`
  &.RaMenuItemLink-active {
    background: rgba(176, 193, 212, 0.5);
  }
`;

export const MyMenu = () => {
  const dataProvider = useDataProvider();
  const roles = useMemo(
    () => JSON.parse(localStorage.getItem("roles")) || [],
    []
  );
  const [cmsPages, setCmsPages] = useState([]);

  const fetchCmsPages = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList("cms-pages", {
          pagination: {},
          sort: {},
          filter: {},
        })
      ).data;
      const customMenuItems = [];
      data.forEach((page) => {
        customMenuItems.push(
          <StyledMenuItemLink
            key={page.id}
            to={"/pages/" + page.url}
            primaryText={page.menu}
            leftIcon={
              page.icon ? (
                <img
                  src={page.icon.url}
                  alt={page.menu + page.id}
                  style={{ width: "24px", height: "24px" }}
                />
              ) : (
                <BookIcon />
              )
            }
          />
        );
      });

      setCmsPages(customMenuItems);
    } catch (e) {
      console.log(e);
    }
  }, [dataProvider]);

  let menuItems = [];

  useEffect(() => {
    if (
      roles.includes("ROLE_CREATIVE")
    ) {
      fetchCmsPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  if (roles) {
    let regularItems = [
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="admins"
          to="/users"
          primaryText="Admins"
          leftIcon={<AssignmentIcon />}
        />
      ),
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="creatives"
          to="/creatives"
          primaryText="Creatives"
          leftIcon={<PersonIcon />}
        />
      ),
      <StyledMenuItemLink
        key="products"
        to="/products"
        primaryText="Products"
        leftIcon={<BookIcon />}
      />,
      <StyledMenuItemLink
        key="orders"
        to="/orders"
        primaryText="Orders"
        leftIcon={<BorderAllIcon />}
      />,
      
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="creative-events"
          to="/creative-events"
          primaryText="Events"
          leftIcon={<EventIcon />}
        />
      ),
      roles.includes("ROLE_CREATIVE") && (
        <StyledMenuItemLink
          key="account"
          to="/account"
          primaryText="Account"
          leftIcon={<AccountIcon />}
        />
      ),
      roles.includes("ROLE_CREATIVE") && (
        <StyledMenuItemLink
          key="settings"
          to="/settings"
          primaryText="Settings"
          leftIcon={<SettingsIcon />}
        />
      ),
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="files"
          to="/files"
          primaryText="Files"
          leftIcon={<InsertDriveFileIcon />}
        />
      ),
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="cms"
          to="/cms-pages"
          primaryText="Cms Pages"
          leftIcon={<DashboardIcon />}
        />
      ),
      roles.includes("ROLE_ADMIN") && (
        <StyledMenuItemLink
          key="cms-blocks"
          to="/cms-blocks"
          primaryText="Cms Blocks"
          leftIcon={<DashboardIcon />}
        />
      ),
    ];
    if (cmsPages.length > 0) {
      return regularItems.concat(cmsPages);
    }

    return regularItems;
  }

  return menuItems;
};
