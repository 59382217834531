import React, { useState, useMemo } from 'react';
import { Button, Popover } from '@mui/material';
import { Datagrid, ArrayField } from 'react-admin';

const PopupList = ({ children, triggerText, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const onClickPopup = (event) => {
        event.stopPropagation();
    }

    const open = useMemo(() => {
        return Boolean(anchorEl)
    }, [anchorEl]);

    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                {triggerText}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClick={onClickPopup}
            >
                <ArrayField { ...props }>
                    <Datagrid bulkActionButtons={false}>
                        {children}
                    </Datagrid>
                </ArrayField>
            </Popover>
        </div>
    );
};

export default PopupList;
