import { useEffect, useState, useCallback, useMemo } from "react";
import { getApiRoute } from '../config/routes';
import useApi from "./useApi";

const useFiles = () => {
  const [filesSettings, setFilesSettings] = useState(null)
  const { api } = useApi()

  useEffect(() => {
    api.get(getApiRoute('settings/files')).then(({ data }) => {
        setFilesSettings(data)
    })
  }, [api])

  const imageMimeTypes = useMemo(() => {
    return "image/png,image/jpeg,image/jpg"
  }, [])

  const toBase64 = useCallback((file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  }), []);
  
  const filesFormat = useCallback(async (files, concept = true) => {
      const filesArray = Array.isArray(files) ? files : Array.from(files);
  
      return Promise.all(filesArray.map(async (file, index) => {
          const base64 = await toBase64(file);
          const substring = 'base64,'
          const subIndex = base64.indexOf(substring) + substring.length
          const fileBase64 = base64.slice(subIndex)
  
          return {
            key: index,
            content: fileBase64,
            name: file.name,
            concept
          };
      }));
  }, [toBase64])
  

  return {
    filesFormat,
    filesSettings,
    imageMimeTypes
  };
};

export default useFiles;
