import React, { useState, useCallback, useMemo } from "react";
import {
  useNotify,
} from "react-admin";
import styled from "styled-components";
import { MenuItem, InputLabel, Checkbox, FormControlLabel, TextField as TextFieldMui, Button, FormControl, Select } from '@mui/material';
import useApi from "../../hooks/useApi";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { CustomRichTextInput } from "../rich-text-input";

const SvgWrapper = styled.div`
  width: 40px;
  height: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const AddTrackForm = ({ lines, creativeOptions, shippers, onAdd, ...props }) => {
  const { api } = useApi();
  const notify = useNotify();
  const [newTrack, setNewTrack] = useState({
    noTrackingCode: false,
    creative: null,
    shipper: null,
    number: null,
    link: null,
    personalMessage: null
  })

  const newTrackIsCustomShipper = useMemo(() => {
    const shipper = shippers.find((shipper) => shipper.id === newTrack.shipper)
    return shipper?.custom
  }, [newTrack.shipper, shippers])

  const onChangeNewTrack = useCallback((key, value) => {
    setNewTrack(prev => ({ ...prev, [key]: value }))
  }, [])

  const disabledConfirm = useMemo(() => {
    if (newTrack.noTrackingCode) {
      return false
    }

    if (newTrack.shipper && newTrack.number) {
      return false
    }
    
    return true
  }, [newTrack.noTrackingCode, newTrack.number, newTrack.shipper])

  const addTrack = useCallback(async () => {
    const payload = lines.map((line, index) => {
      if (newTrack.noTrackingCode) {
        return {
          key: index + 1,
          id: line,
          noCode: true,
        } 
      }

      var urlRegex = /(https?:\/\/[^\s]+)/g;
      if (newTrackIsCustomShipper && !urlRegex.test(newTrack.link)) {
        notify("Url is not valid", "error");
        return undefined
      }

      return {
        key: index + 1,
        id: line,
        shipper: newTrack.shipper,
        noCode: newTrack.noTrackingCode,
        link: newTrackIsCustomShipper ? newTrack.link : undefined,
        number: newTrackIsCustomShipper ? undefined : newTrack.number,
        personalMessage: newTrack.personalMessage ? newTrack.personalMessage : undefined
      }
    }).filter(item => item)

    await api.post(getApiRoute('order-lines/bulk-add-track'), payload).then(() => {
      setNewTrack({})
      notify("The item(s) have been marked as shipped and the customer is notified by email", "success");
      if (onAdd) {
        onAdd()
      }
    }).catch(err => {
      notify(errorMessageHandler(err), "error");
    })
  }, [lines, api, newTrack.noTrackingCode, newTrack.link, newTrack.shipper, newTrack.number, newTrack.personalMessage, newTrackIsCustomShipper, notify, onAdd])

  return (
    <>
    <FormControlLabel value={newTrack.noTrackingCode} control={<Checkbox checked={newTrack.noTrackingCode} onChange={(e) => { onChangeNewTrack('noTrackingCode', e.target.checked) }} />} label="Sent without a tracking code" inputProps={{ 'aria-label': 'controlled' }} />
    {!newTrack.noTrackingCode && 
    <>
    <div style={{ marginTop: '10px' }}>
      <FormControl size="small" fullWidth>
        <InputLabel id="shipperNewTrackLabel">Shipper</InputLabel>
        <Select
          id="shipperNewTrack"
          labelId="shipperNewTrackLabel"
          value={newTrack.shipper}
          label="Shipper"
          defaultValue=""
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={(e) => { onChangeNewTrack('shipper', e.target.value) }}
        >
          {shippers.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <SvgWrapper dangerouslySetInnerHTML={{ __html: option.svg }}></SvgWrapper>
                {option.name}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    {newTrack.shipper && !newTrackIsCustomShipper &&
    <div style={{ marginTop: '10px' }}>
      <TextFieldMui fullWidth size="small" label="Track and Trace Number" value={newTrack.number} onChange={(e) => { onChangeNewTrack('number', e.target.value) }} />
    </div>
    }
    {newTrack.shipper && newTrackIsCustomShipper && 
    <div style={{ marginTop: '10px' }}>
      <TextFieldMui fullWidth size="small" label="Track and Trace Link" value={newTrack.link} onChange={(e) => { onChangeNewTrack('link', e.target.value) }} />
    </div>
    }
    </>
    }
    <div style={{ marginTop: '10px', marginBottom: '5px', fontSize: '14px', color: 'rgba(0,0,0,0.6)' }}>Add personal e-mail message</div>
    <div style={{ padding: '15px', border: '1px dashed lightgray'}}>
        <i>Beste klant,</i>
        <CustomRichTextInput
          label=" "
          source="personalMessage"
          placeholder="Write something nice to your customer here, or leave empty"
          record={{ personalMessage: newTrack.personalMessage }}
          value={newTrack.personalMessage}
          onChange={(val) => { onChangeNewTrack('personalMessage', val) }}
        />

        <i>De volgende artikelen zijn naar jou onderweg:</i>
        <ul style={{ padding: 0, listStyle: 'inside', margin: '0' }}>
          <li>...</li>
          <li>...</li>
        </ul>
    </div>
    <div style={{ marginTop: '10px' }}>
      <Button disabled={disabledConfirm} variant="contained" onClick={addTrack}>Confirm shipment & Notify Customer</Button>
    </div>
    </>
  )
}

export default AddTrackForm