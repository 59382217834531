import React, { useCallback } from "react";
//import React, { useState } from "react";
import { Divider } from "@mui/material";

import {
  Edit,
  ImageField,
  SimpleForm,
  useUpdate,
  useNotify,
  useRedirect,
  BooleanInput,
  FileInput,
  FormDataConsumer
} from "react-admin";
import useApi from "../../hooks/useApi";
import useFiles from "../../hooks/useFiles";
import { Section, SectionInfo, SectionInputs } from "../section";
import { TextInput } from "../app/OutlinedInputs";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { getApiRoute } from "../../config/routes";
import defaultImage from '../../assets/images/file.png'
import withTitle from '../with-title'
// import { resizeImage } from "../../utils/images";

const CmsPageEdit = (props) => {
  const { api } = useApi();
  const notify = useNotify();
  const [update] = useUpdate();
  // const [icon, setIcon] = useState(null);
  const redirect = useRedirect();
  const { filesFormat, imageMimeTypes } = useFiles()


  const onChangeFile = useCallback((file) => {
    if(!file || !file.length) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
  }, [notify])

  const save = async (values) => {
    try {
      let iconKey = null
      if (values.icon?.rawFile) {
        const files = [values.icon.rawFile]
        const filesArr = await filesFormat(files)
        const res = await api.post(getApiRoute("files"), filesArr).catch((e) => {
          notify(errorMessageHandler(e), "error", {}, false, 100000);
        });
        iconKey = res.data[0]?.data?.key
      }
      const data = {
        ...values,
        icon: iconKey || values.icon?.key || null,
      };

      await update(
        "cms-pages",
        {
          id: values.id,
          data,
        },
        {
          returnPromise: true,
          onSuccess: () => {
            notify("Changes saved", "success");
            redirect("/cms-pages");
          },
        }
      );
    } catch (error) {
      notify(errorMessageHandler(error), "error", {}, false, 100000);
    }
  };

  /*const onChangeImage = async (file) => {
    if (!file) {
      setIcon(null);
    }
    let reader = new FileReader();

    reader.addEventListener("load", () => {
      let image = new Image();
      image.src = reader.result;
      image.addEventListener("load", async () => {
        let formData = new FormData();
        const resizedFile = await resizeImage(file)
        formData.append("file", resizedFile);
        formData.append("sort", 1);
        api
          .post(getApiRoute("images"), formData)
          .then((image) => {
            setIcon(image.data);
          })
          .catch((e) => {
            console.log(e);
            notify(errorMessageHandler(e), "error", {}, false, 100000);
          });
      });
    });

    if (file?.type && file?.type.indexOf('image') !== -1) {
      reader.readAsDataURL(file);
    }
  };*/

  const onErrorImage = (e) => {
    e.target.src = defaultImage
  }

  return (
    <div>
      <h1>Edit</h1>
      <Divider />
      <Edit {...props}>
        <SimpleForm onSubmit={save}>
          <Section>
            <SectionInfo title={"Page details"}></SectionInfo>
            <SectionInputs>
              <TextInput
                variant="outlined"
                source="title"
                type="text"
                label="Page title"
                fullWidth
              />
              <TextInput
                variant="outlined"
                source="menu"
                label="Menu name"
                type="text"
                fullWidth
              />
              <TextInput
                variant="outlined"
                source="url"
                type="text"
                fullWidth
              />
              <BooleanInput name={"membersOnly"} label='Show for members' source="membersOnly" />
              <BooleanInput name={"nonMembersOnly"} label='Show for non-members' source="nonMembersOnly" />
            </SectionInputs>
          </Section>
          <Section>
            <SectionInfo title={"HTML Body"}></SectionInfo>
            <SectionInputs>
              <TextInput
                multiline
                source="body"
                label="Body"
                sx={{
                  [`& textarea`]: {
                    minHeight: '20em',
                  },
                }}
              />
            </SectionInputs>
          </Section>
          <Section>
            <SectionInfo title={"Icon"}></SectionInfo>
            <SectionInputs>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                  <FileInput source="icon" onChange={onChangeFile} accept={imageMimeTypes}>
                    <ImageField source={formData?.icon?.url ? 'url' : 'src'} onError={onErrorImage} />
                  </FileInput>
                )}}
              </FormDataConsumer>
              
              {/* <ImageInput
                onChange={onChangeImage}
                source="icon"
                label=""
                accept="image/*"
              >
                <ImageField source="absoluteUrl" title="title" />
              </ImageInput> */}
              {/* {icon && (
                <StyledImage src={icon.absoluteUrl} alt={icon.id} />
              )} */}
            </SectionInputs>
          </Section>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default withTitle(CmsPageEdit, 'CMS Page Edit');
