import React, { useState, useCallback, useEffect } from "react";
import useApi from "../../hooks/useApi";
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  PasswordInput,
  FormDataConsumer
} from "react-admin";
import { InfoLink, Section, SectionInputs } from "../section";
import { TextInput } from "../app/OutlinedInputs";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from '../with-title'
import sha1 from 'js-sha1'
import axios from 'axios'
import { getApiRoute } from "../../config/routes";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AccountList = (props) => {
  const [creative, setCreative] = useState(undefined);
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [notifyText, setNotifyText] = useState('')

  const { api } = useApi();
  const notify = useNotify();

  const fetchCreative = useCallback(async () => {
    try {
      const creative = (await api.getLoggedinUserCreative())?.data;
      setCreative(creative);
    } catch (e) {
      console.log(e);
    }
  }, [api]);


  useEffect(() => {
    fetchCreative();
  }, [fetchCreative]);

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton size="medium" />
      </Toolbar>
    );
  };

  const checkPassword = useCallback(async (password) => {
    const hash = sha1(password).toUpperCase();
    const prefix = hash.slice(0, 5);
    const suffix = hash.slice(5);

    try {
      const response = await axios.get(`https://api.pwnedpasswords.com/range/${prefix}`, { timeout: 5000 });
      const regex = new RegExp(`(${suffix}):([0-9]+)`, 'mi');
      const matches = response.data.split('\n').filter(line => regex.test(line));

      if (matches.length > 0) {
        // const match = regex.exec(matches[0]);
        setNotifyText('This password has been hacked, please choose a different password')
        return false
      }
      return true
    } catch (error) {
      console.error('err', error)
    }
  }, [])

  const saveFunction = useCallback(async (values) => {
    try {
      const { email, plainPassword } = values.user;

      const data = {
        email: values.user.email !== creative.user.email ? email : undefined,
        plainPassword
      }
      await api.patch(getApiRoute('me'), data).catch(error => notify(errorMessageHandler(error), "danger"))

      setCreative(null)
      fetchCreative()
    } catch (error) {
      notify(errorMessageHandler(error), "danger");
    }},
    [fetchCreative, notify, creative?.user.email, api]
  );

  const save = useCallback(async (values) => {
    const { plainPassword } = values.user;
    let passwordValid = true

    if (plainPassword) {
      passwordValid = await checkPassword(plainPassword)
    }

    if (!passwordValid) {
      setConfirmDialog(true)
    } else {
      saveFunction(values)
    }
  }, [checkPassword, saveFunction])

  const equalToPassword = useCallback((value, allValues) => {
    if (value !== allValues.user.plainPassword) {
        return 'The two passwords must match';
    }
  }, [])

  const passwordValidate = useCallback((value, allValues) => {
    if (value && value.length < 8) {
      return 'This value is too short. It should have 8 characters or more.'
    }
  }, [])

  const handleCloseConfirmDialog = useCallback(() => {
    setConfirmDialog(false);
  }, []);

  const handleConfirmDialog = useCallback((values) => {
    saveFunction(values)
    handleCloseConfirmDialog();
  }, [handleCloseConfirmDialog, saveFunction])


  if (!creative) {
    return <div></div>;
  }

  return (
    <div>
      <Edit
        {...props}
        id={creative.id}
        resource={"creatives"}
        redirect={false}
      >
        <SimpleForm
          toolbar={<CustomToolbar />}
          onSubmit={save}
        >
          <Section style={{ flexDirection: 'column' }}>
            <b>Your shop page can be found here:</b>
            <InfoLink
              style={{ color: 'dodgerblue' }}
              href={creative.shopUrl}
              target="_blank"
            >
              { creative.shopUrl }
            </InfoLink>
          </Section>
          <Section>
            <SectionInputs>
              <TextInput
                  label="Email"
                  source="user.email"
              />
              <PasswordInput
                  source="user.plainPassword"
                  label="New password"
                  variant="outlined"
                  inputProps={{ autoComplete: "new-password" }}
                  validate={passwordValidate}
                />
              <PasswordInput
                  source="user.repeatPassword"
                  label="Repeat new password"
                  variant="outlined"
                  inputProps={{ autoComplete: "new-password-repeat" }}
                  validate={equalToPassword}
                />
            </SectionInputs>
          </Section>
          <Dialog onClose={handleCloseConfirmDialog} open={confirmDialog}>
            <DialogTitle>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              Are you sure
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseConfirmDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
            </div>
            </DialogTitle>
            <DialogContent>
              {notifyText}
            </DialogContent>
            <DialogActions>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <Button variant="contained" onClick={() => { handleConfirmDialog(formData) }}>Confirm</Button>
                )}
              </FormDataConsumer>
              <Button variant="contained" color="error" onClick={handleCloseConfirmDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default withTitle(AccountList, 'Account List');
