import * as React from "react";
import {
    List,
    TextField,
    Datagrid,
    Filter,
    TextInput,
    DateField,
    SimpleList
} from "react-admin";
import { EmailOnboardingButton } from "./actions/EmailOnboardingButton";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';
import { formatUTCDate } from "../../utils/common";

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      source="search"
      name="search"
      alwaysOn
    />
  </Filter>
);

const UserList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        <div><b>Email:</b> {record.email || '-'}</div>
        <div><b>Created At:</b> {formatUTCDate(record.createdAt)}</div>
        <div style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}>
          <EmailOnboardingButton variant="contained" sx={{flex: '1'}} />
        </div>
      </div>
    )
  }
  return (
    <List
      {...props}
      perPage={50}
      exporter={false}
      resource={"users"}
      filters={<ListFilter />}
      filter={{
        roles: 'ROLE_ADMIN',
      }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => <div><b>ID:</b> {record.id}</div>}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="email" />
          <EmailOnboardingButton />
          <DateField source="createdAt.formatted3" label="Created At" locales="nl-Nl" />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(UserList, 'User List');
