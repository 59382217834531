import React, { useCallback } from "react";
import {
  Create,
  SimpleForm,
  useNotify,
  useCreate,
  useRedirect,
  Toolbar,
  SaveButton,
  FileInput,
  ImageField
} from "react-admin";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from "../with-title";
import { Section, SectionInputs } from "../section";
import defaultImage from '../../assets/images/file.png'
import useFiles from '../../hooks/useFiles'

const FileCreate = (props) => {
  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();
  const { filesFormat, filesSettings } = useFiles()

  const onChangeFile = useCallback((file) => {
    if(!file || !file.length) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
  }, [notify])

  const save = useCallback(
    async (values) => {
      const files = values.files.map(file => file.rawFile)
    
      const res = await filesFormat(files, false)

      try {
        await create(
          "files",
          {
            data: res,
          },
          {
            returnPromise: true,
            onSuccess: () => {
              notify("A files is being added", "success");
              redirect("/files");
            },
          }
        );
      } catch (error) {
        console.log(error);
        notify(errorMessageHandler(error), "error", {}, false, 100000);
      }
    },
    [create, filesFormat, notify, redirect]
  );

  const onErrorImage = (e) => {
    e.target.src = defaultImage
  }

  const PostEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
      <Create {...props}>
        <SimpleForm onSubmit={save} toolbar={<PostEditToolbar />}>
            <Section>
                <SectionInputs>
                    <FileInput source="files" multiple accept={filesSettings?.mimeTypes} onChange={onChangeFile}>
                        <ImageField source="src" onError={onErrorImage}/>
                    </FileInput>
                </SectionInputs>
            </Section>
        </SimpleForm>
      </Create>
  );
};

export default withTitle(FileCreate, "File Create");
