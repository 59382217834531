import * as React from "react";
import {
  List,
  TextField,
  Datagrid,
  Filter,
  TextInput,
  DateField,
  BooleanField,
  EditButton,
  NullableBooleanInput,
  SimpleList,
  TopToolbar,
  CreateButton,
  Button,
  FunctionField
} from "react-admin";
import { ImpersonateButton } from "./actions/ImpersonateButton";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';
import { formatUTCDate } from "../../utils/common";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ProductsButton } from "./actions/ProductsButton";
import { OrdersButton } from "./actions/OrdersButton";
import { EmailOnboardingButton } from "./actions/EmailOnboardingButton";
import { getApiRoute } from "../../config/routes";

const handleDownload = () => {
  let url = 'creatives/export/exact-online'

  const downloadUrl = getApiRoute(url)

  window.open(downloadUrl, '_blank')
}

const ListFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="search" name="search" alwaysOn sx={{ maxWidth: '200px' }} />
      <NullableBooleanInput source="member" name="member" alwaysOn sx={{ maxWidth: '200px' }}/>
  </Filter>
);

const ListActions = (props) => {
  return (
    <TopToolbar sx={{ alignSelf: 'flex-start', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start !important', sm: 'center' } }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CreateButton size="medium" />
        <Button size="medium" onClick={handleDownload} label="Export for EO" />
      </div>
    </TopToolbar>
  );
};

const CreativeList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        <div><b>Email:</b> {record.user.email || '-'}</div>
        <div><b>Name:</b> {record.name}</div>
        <div><b>Products:</b> {record.kpi.productsCount}</div>
        <div><b>Created At:</b> {formatUTCDate(record.createdAt?.formatted3)}</div>
        <div><b>On Vacation From:</b> {formatUTCDate(record.onVacationFrom?.formatted3)}</div>
        <div><b>On Vacation Until:</b> {formatUTCDate(record.onVacationUntil?.formatted3)}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>Member:</b> {record.member ? <CheckIcon fontSize="small" sx={{ ml: '4px' }}/> : <CloseIcon/>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>Active:</b> {record.active ? <CheckIcon fontSize="small" sx={{ ml: '4px' }}/> : <CloseIcon/>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginTop: '8px' }}>
          <ProductsButton variant="contained" />
          <OrdersButton variant="contained" />
          <ImpersonateButton variant="contained" />
          <EmailOnboardingButton variant="contained" />
        </div>
      </div>
    )
  }

  return (
    <List
      {...props}
      perPage={50}
      exporter={true}
      filters={<ListFilter />}
      actions={<ListActions {...props} />}
      sort={{ field: "id", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => <div><b>ID:</b> {record.id}</div>}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" label={"Id"} />
          <TextField source="user.email" label={"Email"} />
          <TextField source="name" label={"Name"} />
          <EmailOnboardingButton />
          <FunctionField
            label="Products"
            source="kpiProductsCount"
            render={(record) => {
              return (
                <ProductsButton label={"Products"} />
              )
            }}
          />
          <FunctionField
            label="Orders"
            source="kpiOrdersCount"
            render={(record) => {
              return (
                <OrdersButton label={"Orders"} />
              )
            }}
          />
          
          <DateField source="createdAt.datetime" label="Created At" locales="nl-Nl" />
          <DateField source="onVacationFrom.datetime" label="On Vacation From" locales="nl-Nl" />
          <DateField source="onVacationUntil.datetime" label="On Vacation Until" locales="nl-Nl" />
          <BooleanField source="member" />
          <BooleanField source="active" />
          <ImpersonateButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(CreativeList, 'Creative List');
