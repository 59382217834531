import PersonIcon from "@mui/icons-material/Person";
import UserCreate from "./UserCreate";
import UserList from "./UserList";
import UserEdit from "./UserEdit";

const users = {
  label: "Users",
  edit: UserEdit,
  list: UserList,
  create: UserCreate,
  icon: PersonIcon,
};

export default users;
