import { SelectInput } from "../app/OutlinedInputs";
import useApi from "../../hooks/useApi";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { getApiRoute } from "../../config/routes";
import { useInput, useRecordContext, useNotify } from "react-admin";
import { useState, useEffect, useMemo } from "react";

export const VatInput = ({ source, isEdit = false, creativeVat, isKor = null, isOutsideNl = null, ...props }) => {
  // isKor - creative.kor
  // creativeVat - only for products (null for creative)
  // record - product or creative  
  const record = useRecordContext()
    const [vat, setVat] = useState(null)
    const [vats, setVats] = useState([]);
    const { api } = useApi();
    const notify = useNotify();
    const {
        field: { onChange },
    } = useInput({ record, source, ...props })

    useEffect(() => {
        api
            .get(getApiRoute("creative-vats"))
            .then(({ data }) => {
                setVats(data?.data || []);
            })
            .catch((e) => {
                notify(errorMessageHandler(e), "error", {}, false, 100000);
            });
    }, [api, notify]);

    const vatKorDefault = useMemo(() => vats.find(item => item.kor), [vats]);
    const vatDefault = useMemo(() => vats.find(item => item.default), [vats]);
    const vatOutsideNl = useMemo(() => vats.find(item => item.outsideNl), [vats]);


    const vatsFiltered = useMemo(() => {
        let filtered = vats
        const currentVat = record?.vat || creativeVat

        if (isOutsideNl && !isKor) {
            filtered = [...vats.filter(item => item.outsideNl && item.id !== currentVat?.id), currentVat]
        } else if (!isOutsideNl && !isKor) {
            filtered = [...vats.filter(item => !item.outsideNl && item.id !== currentVat?.id), currentVat]
        }

        return filtered.filter(item => item).sort((a, b) => a.id - b.id)
    }, [vats, record, creativeVat, isOutsideNl, isKor])

    useEffect(() => {
        if (vats.length) {
            let initialVat = vatDefault?.id || '';

            if (isEdit && record?.vat) {
                initialVat = record.vat.id || record.vat;
            } else if (creativeVat) {
                initialVat = creativeVat.id || creativeVat;
            } else if (isOutsideNl && !isKor) {
                initialVat = vatOutsideNl?.id || '';
            } else if (isKor) {
                initialVat = vatKorDefault?.id || '';
            }

            setVat(initialVat);
        }
    }, [vats, vatDefault, vatOutsideNl, vatKorDefault, creativeVat, isEdit, record, isKor, isOutsideNl]);

    useEffect(() => {
        if (vat !== null) {
            onChange(vat.id || vat)
        }
    }, [vat, onChange])

    const roles = useMemo(() => JSON.parse(localStorage.getItem("roles")) || [], []);
    const isDisabled = useMemo(() => roles.includes('ROLE_ADMIN') ? false : isKor ?? false, [roles, isKor]);

    if (!vatsFiltered.length || vat === null) {
        return null
    }

    return (
        <SelectInput
            source="vat"
            label="VAT"
            choices={vatsFiltered.map(item => ({ id: item.id, name: item.name || item.id.toString() }))}
            value={vat}
            onChange={(e) => setVat(e.target.value)}
            style={isDisabled ? { pointerEvents: 'none', width: '100%' } : { width: '100%' }}
            {...props}
        />
    );
};