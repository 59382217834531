import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';
import { Fragment, Slice } from 'prosemirror-model';

export const CustomPasteExtension = Extension.create({
  name: 'customPaste',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            const allowedTags = {
              paragraph: true,
              heading: true,
              bold: true,
              italic: true,
              bulletList: true,
              orderedList: true,
              listItem: true,
              hardBreak: true
            };

            const filterNode = (node) => {
              if (!node) {
                console.error('Node is null');
                return Fragment.empty;
              }

              let content = Fragment.empty;
              node.content.forEach(childNode => {
                if (childNode.isText) {
                  content = content.append(Fragment.from(childNode));
                } else {
                  const filteredChild = filterNode(childNode);
                  if (!filteredChild || filteredChild.nodeSize === 0) {
                    console.error('Filtered child is empty for node:', node.type.name);
                  } else {
                    content = content.append(filteredChild);
                  }
                }
              });

              // console.log(content, 'content')

              if (!allowedTags[node.type.name]) {
                console.warn('Node type not allowed, converting to paragraph:', node.type.name);
                return view.state.schema.nodes.paragraph.create(null, content, node.marks);
              }

              if (node.type.name === 'heading' && node.attrs.level !== 3) {
                console.warn('Converting non-level 3 heading to paragraph:', node.attrs.level);
                return view.state.schema.nodes.paragraph.create(null, content, node.marks);
              }

              if (node.type.name === 'listItem' && content.size === 0) {
                console.warn('Empty listItem detected, adding placeholder paragraph');
                content = Fragment.from(view.state.schema.nodes.paragraph.create(null, view.state.schema.text(' ')));
              }
              return node.type.create(node.attrs, node.content, node.marks);
            };

            const filteredContent = [];
            slice.content.forEach(node => {
              const filteredNode = filterNode(node);
              // console.log(filteredNode, 'filteredNode')
              // if (!filteredNode || !filteredNode.content.size) {
              //   console.error('Filtered node is null or empty:', filteredNode ? filteredNode.type.name : 'null');
              // } else {
              //   filteredContent.push(filteredNode);
              // }
              filteredContent.push(filteredNode);
            });

            if (filteredContent.length === 0) {
              console.error('No valid content to insert');
              return false;
            }

            const filteredSlice = new Slice(Fragment.from(filteredContent), slice.openStart, slice.openEnd);
            let transaction = view.state.tr.replaceSelection(filteredSlice);

            if (transaction.docChanged) {
              view.dispatch(transaction);
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
