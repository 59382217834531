import { readAndCompressImage } from 'browser-image-resizer';

export const MAX_IMAGE_WIDTH = 3840;
export const MAX_IMAGE_HEIGHT = 2160;
export const IMAGE_QUALITY = 0.9; // 90%
export const MAX_IMAGE_SIZE_MB = 25;

export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    let reader = new FileReader();

    reader.addEventListener("load", () => {
      img.src = reader.result;

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        const shouldResize = width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT;

        if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
          const aspectRatio = width / height;
          if (width > height) {
            width = MAX_IMAGE_WIDTH;
            height = width / aspectRatio;
          } else {
            height = MAX_IMAGE_HEIGHT;
            width = height * aspectRatio;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const newImageDataUrl = canvas.toDataURL(file.type, IMAGE_QUALITY);
        const compressedBlob = dataURLToBlob(newImageDataUrl);

        if (shouldResize || compressedBlob.size < file.size) {
          resolve(compressedBlob);
        } else {
          resolve(file);
        }
      };
    });

    reader.readAsDataURL(file);
  });
};

// Helper function to convert data URL to Blob
const dataURLToBlob = (dataurl) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToFile = (theBlob, fileName) => {
  return new File([theBlob], fileName, { lastModified: new Date() })
}

const resizeConfig = {
  quality: 0.9,
  maxWidth: 1600,
  maxHeight: 1600
};

export const resizeImage = async (file) => {
  try {
    const res = await readAndCompressImage(file, resizeConfig)
    if (res) {
      return blobToFile(res, file.name)
    }
    return null
  } catch (error) {
    console.log(error, 'error')
  }
}
