import React, { useCallback, useState } from "react";
import {
  Login as BaseLogin,
  useNotify,
  Notification,
  useRedirect,
} from "react-admin";
import { TextField, Button, CssBaseline } from "@mui/material";
import styled from "styled-components";
import { httpClient } from "../../providers/httpClient";
import { getApiRoute } from "../../config/routes";
import { getUrlParams } from "../../utils/uri";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from "../with-title";
import loginBck from '../../assets/images/login-swan.jpg'

const InputsWrapper = styled.div`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SubmitWrapper = styled.div`
  padding: 0 1em 1em 1em;
`;

export const SetPassword = withTitle(React.memo(() => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const notify = useNotify();
  const redirect = useRedirect();

  const token = getUrlParams()["token"];

  const submit = useCallback(
    (e) => {
      e.preventDefault();

      if (password1 !== password2) {
        notify("passwords are different", "error");
        return;
      }
      if (password1.length < 6) {
        notify("passwords are too short", "error");
        return;
      }
      httpClient(`${getApiRoute("users/password")}/${token}`, {
        method: "POST",
        body: JSON.stringify({ password: password1 }),
      })
        .then(() => {
          redirect("/login");
        })
        .catch((e) => {
          notify(errorMessageHandler(e), "error", {}, false, 100000);
        });
    },
    [notify, password1, password2, redirect, token]
  );

  return (
    <BaseLogin
      // A random image that changes everyday
      backgroundImage={loginBck}
    >
      <CssBaseline />
      <form onSubmit={submit}>
        <InputsWrapper>
          <div>
            <TextField
              id="password1"
              label="Enter Password"
              type="password"
              style={{ width: "100%" }}
              value={password1}
              required
              onChange={(event) => setPassword1(event.target.value)}
              size="small"
            />
          </div>
          <div>
            <TextField
              id="password2"
              label="Confirm Password"
              type="password"
              style={{ width: "100%" }}
              value={password2}
              required
              onChange={(event) => setPassword2(event.target.value)}
              size="small"
            />
          </div>
        </InputsWrapper>

        <SubmitWrapper>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Set Password
          </Button>
        </SubmitWrapper>
      </form>

      <Notification />
    </BaseLogin>
  );
}), 'Set Password')
