import React, { useMemo } from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";

import { createDataProvider } from "../../providers/dataProvider";
import authProvider from "../../providers/authProvider";
import { Login } from "../login";
import products from "../products";
import productLog from "../product-logs";
import settings from "../settings";
import account from "../account";
import admins from "../users";
import creatives from "../creatives";
import files from "../files";
import orders from "../orders";
import events from "../events";
import cmsPages from "../cms-pages";
import cmsBlocks from "../cms-blocks";
import theme from "./theme";
import CustomLayout from "./layout";
import customPages from "../custom-pages";
import { SetPassword } from "../set-password";
import useApi from "../../hooks/useApi";
import { ResetPassword } from '../reset-password'
import { OneTimeLogin } from '../one-time-login'

const App = () => {
  const { api } = useApi();

  const dataProvider = useMemo(() => createDataProvider({ api }), [api]);

  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      layout={CustomLayout}
      disableTelemetry
    >
      {() => {
        const roles = JSON.parse(localStorage.getItem('roles')) || []
        return [
          roles.includes('ROLE_ADMIN') && <Resource name="users" {...admins} />,
          roles.includes('ROLE_ADMIN') && (
            <Resource name="creatives" {...creatives} />
          ),
          <Resource name="products" {...products} />,
          <Resource name="orders" {...orders} />,
          <Resource name="categories" />,
          <Resource name="pages" {...customPages} />,
          <Resource name="tags" />,
          <Resource name="product-logs" {...productLog} />,
          roles.includes('ROLE_CREATIVE') && (
            <Resource name="settings" {...settings} />
          ),
          roles.includes('ROLE_ADMIN') && (
            <Resource name="creative-events" {...events} />
          ),
          roles.includes('ROLE_CREATIVE') && (
            <Resource name="account" {...account} />
          ),
          roles.includes('ROLE_ADMIN') && <Resource name="files" {...files} />,
          roles.includes('ROLE_ADMIN') && (
            <Resource name="cms-pages" {...cmsPages} />
          ),
          roles.includes('ROLE_ADMIN') && (
            <Resource name="cms-blocks" {...cmsBlocks} />
          ),
        ]
      }}
      <CustomRoutes noLayout>
        <Route
          exact
          key="onetime-login"
          path="/onetime-login"
          element={<OneTimeLogin />}
        />
        <Route
          exact
          key="set-password"
          path="/set-password"
          element={<SetPassword />}
        />
        <Route
          exact
          key="reset-password"
          path="/reset-password"
          element={<ResetPassword />}
        />
      </CustomRoutes>
    </Admin>
  )
};
export default App;
