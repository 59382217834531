import * as React from "react";
import { List, TextField, Datagrid, FunctionField, SimpleList } from "react-admin";
import styled from "styled-components";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';

const StyledImage = styled.img`
  width: 40px;
  height: 40px;
`;

const CmsPageList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        {renderCmsPageImage(record)}
        <div><b>Title:</b> {record.title}</div>
        <div><b>Menu:</b> {record.menu}</div>
        <div><b>URL:</b> {record.url}</div>
      </div>
    )
  }

  const renderCmsPageImage = (record) => {
    return record.icon ? (
      <StyledImage src={record.icon.url} alt="page image" />
    ) : null;
  }

  return (
    <List {...props} exporter={false} perPage={50}>
      {isSmall ? (
        <SimpleList
          primaryText={record => <div><b>ID:</b> {record.id}</div>}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="menu" />
          <FunctionField
            label="Image"
            render={renderCmsPageImage}
          />
          <TextField source="url" />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(CmsPageList, 'CMS Page List');
