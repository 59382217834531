import styled from "styled-components";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { MAX_IMAGE_SIZE_MB } from "../../utils/images";

export const InfoLink = styled.a`
  color: #242038;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SectionHeaderRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Section = styled.section`
  display: flex;
  gap: 8px;
  width: 100% !important;
  border-bottom: 1px solid #ccc;
  padding: 20px 0 20px 0;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;


export const SectionCard = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100% !important;
  padding: 20px;
  margin-bottom: 40px;

  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  width: 100%;
  max-width: 640px;
}
  
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;


export const SectionInputs = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin-right: auto;
`;

const SectionInfoBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 320px;

`;

const GrayBox = styled.div`
  display: flex;
  max-width: 320px;
  border: 1px solid rgba(36, 31, 56, 0.1);
  background-color: rgba(36, 31, 56, 0.05);
  padding: 20px;
  color: #242a42;
  gap: 20px;
`;

export const SectionDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SectionInfoImages = () => {
  return (
    <SectionDescriptionWrapper>
      <span>You can reorder images by dragging and dropping them</span>
      <span>Note that we will compress images larger than 4K</span>
      <span>Maximum image size is {MAX_IMAGE_SIZE_MB} mb</span>
    </SectionDescriptionWrapper>
  )
}

export const SectionInfo = ({ children, title }) => {
  return (
    <SectionInfoBody>
      {!!title && <h3 style={{ margin: 0 }}>{title}</h3>}
      {!!children && (
        <GrayBox>
          <InfoIcon />
          <span style={{ wordWrap: 'anywhere' }}>{children}</span>
        </GrayBox>
      )}
    </SectionInfoBody>
  );
};
