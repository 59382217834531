export const categoryTransformer = (response) => {
	return response.map((item) => ({
		...item,
		name: item.name.replace(/&amp;/g, "&"),
		parent: item.parent
			? {
				...item.parent,
				name: item.parent.name.replace(/&amp;/g, "&"),
			}
			: null,
	}));
};
