import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import { useRecordContext } from "react-admin";
import useApi from "../../../hooks/useApi";

export const EmailLoginButton = React.memo((props) => {
  const { api, isSubmitting } = useApi();
  const record = useRecordContext();

  const onClick = useCallback((e) => {
    const id = record?.user ? record?.user?.id : record?.id;
    api.emailOneTimeLogin(id);
    e.stopPropagation();
    e.preventDefault();
  }, [record, api])

  return (
    <Button onClickCapture={onClick} disabled={!record?.user || isSubmitting} {...props}>
      <EmailIcon /> Send&nbsp;magic&nbsp;login&nbsp;link
    </Button>
  );
});
