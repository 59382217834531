import { useDataProvider, Title } from "react-admin";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InnerHTML from 'dangerously-set-html-content'
import { Helmet } from 'react-helmet';

const CustomPage = ({ ...props }) => {
  const params = useParams()
  const { id } = params

  const [page, setPage] = useState(null)
  const dataProvider = useDataProvider()

  const fetchPage = useCallback(async () => {
    try {
      const page = (
        await dataProvider.getOne('pages', {
          id,
          pagination: {},
          sort: {},
          filter: {},
        })
      ).data
  
      setPage(page)
    } catch (e) {
      console.log(e)
    }
  }, [id, dataProvider])

  useEffect(() => {
    fetchPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPage])

  // useEffect(() => {
  //     if (page) {
  //         const titleMetaTag = document.querySelector('meta[name="title"]');
  //         if (titleMetaTag) {
  //             titleMetaTag.setAttribute("content", page.title);
  //         } else {
  //             const newMetaTag = document.createElement("meta");
  //             newMetaTag.name = "title";
  //             newMetaTag.content = page.title;
  //             document.head.appendChild(newMetaTag);
  //         }
  //     }
  // }, [page]);

  if (page) {
    return <>
      <Helmet>
        <title>{page.menu}</title>
      </Helmet>
      <Title title={page.menu} />
      <InnerHTML html={page.body || '<span></span>'} allowRerender />
    </>
  }

  return null
}

export default CustomPage;
