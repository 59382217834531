import React, { useCallback } from "react";
import { Divider } from "@mui/material";

import {
  Edit,
  SimpleForm,
  useNotify,
  useRedirect,
  ImageField,
  useRecordContext,
  FormDataConsumer,
  FileInput
} from "react-admin";
import useApi from "../../hooks/useApi";
import useFiles from "../../hooks/useFiles";
import { Section, SectionInfo, SectionInputs } from "../section";
import { TextInput } from "../app/OutlinedInputs";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { getApiRoute } from "../../config/routes";
import defaultImage from '../../assets/images/file.png'
import withTitle from '../with-title'
import { CustomRichTextInput } from "../rich-text-input";

const CmsBlockEdit = (props) => {
  const { api } = useApi();
  const notify = useNotify();
  const redirect = useRedirect();
  const { filesFormat, imageMimeTypes } = useFiles()

  const onChangeFile = useCallback((file) => {
    console.log(file, 'file')
    if(!file) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
  }, [notify])

  const save = async (values) => {
    try {
      let imageKey = null
      if (values.image?.rawFile) {
        const files = [values.image.rawFile]
        const filesArr = await filesFormat(files)
        const res = await api.post(getApiRoute("files"), filesArr).catch((e) => {
          notify(errorMessageHandler(e), "error", {}, false, 100000);
        });
        imageKey = res.data[0]?.data?.key
      }

      const data = {
        ...values,
        image: imageKey || values.image?.key || null,
      };

      await api.patch(getApiRoute(`cms-blocks/${data.id}`), data).catch(error => notify(errorMessageHandler(error), "danger"))
      notify("Changes saved", "success");
      redirect("/cms-blocks");
    } catch (error) {
      notify(errorMessageHandler(error), "error", {}, false, 100000);
    }
  };

  const onErrorImage = (e) => {
    e.target.src = defaultImage
  }
  
  const PageTitle = () => {
    const record = useRecordContext()
    return <h1>Edit {record?.location}</h1>
  }

  return (
    <div>
      <Divider />
      <Edit {...props}>
        <SimpleForm onSubmit={save}>
          <Section>
            <SectionInfo title={<PageTitle />}></SectionInfo>
            <SectionInputs>
              <TextInput
                variant="outlined"
                source="title"
                type="text"
                label="Block title"
                fullWidth
              />
               <CustomRichTextInput
                label="Content"
                source="content"
                name="content"
              />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    return (
                    <FileInput source="image" accept={imageMimeTypes} onChange={onChangeFile}>
                      <ImageField source={formData?.image?.url ? 'url' : 'src'} onError={onErrorImage} />
                    </FileInput>
                  )}}
                </FormDataConsumer>
              </div>
            </SectionInputs>
          </Section>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default withTitle(CmsBlockEdit, 'CMS Block Edit');
