import React from "react";
import { Notification as BaseNotification } from "react-admin";

const Notification = (props) => {
  return (
    <BaseNotification
      {...props}
      autoHideDuration={10000}
      sx={{ whiteSpace: "pre-wrap" }}
    />
  );
};

export default Notification;
