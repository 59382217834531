import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import { useRecordContext, useRedirect } from "react-admin";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const ProductsButton = React.memo((props) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const onNavigateClick = useCallback(
    (e) => {
      redirect('list', `/products?filter=%7B%22creative%22%3A${record?.id}%7D`)
      e.stopPropagation();
      e.preventDefault();
    },
    [record, redirect]
  );

  return (
    <Button onClickCapture={onNavigateClick} {...props}>
      <FormatListBulletedIcon /> {record.kpi.productsCount}
    </Button>
  );
});
