import SettingsIcon from "@mui/icons-material/Settings";
import SettingsList from "./SettingsList";

const settings = {
  label: "Holiday mode",
  list: SettingsList,
  icon: SettingsIcon,
};

export default settings;
