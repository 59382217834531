import React, { useCallback } from "react";
import {
  Edit,
  SimpleForm,
  useNotify,
  useUpdate,
  useRedirect,
  Toolbar,
  SaveButton,
  DateInput,
  TimeInput,
  DeleteButton
} from "react-admin";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from "../with-title";
import { Section, SectionInputs } from "../section";
import { TextInput } from "../app/OutlinedInputs";
import { formatTimeFromDate } from "../../utils/common";

const EventEdit = (props) => {
  const notify = useNotify();
  const [update] = useUpdate();
  const redirect = useRedirect();


  const save = useCallback(
    async (values) => {
      try {
        await update(
          "creative-events",
          {
            id: values.id,
            data: {
                ...values,
                time: undefined,
                takePlaceOn: values.takePlaceOn.datetime,
                timeFrom: formatTimeFromDate(values.time.from),
                timeTo: formatTimeFromDate(values.time.to)
            },
          },
          {
            returnPromise: true,
            onSuccess: () => {
              notify("An Event is being updated", "success");
              redirect("/creative-events");
            },
          }
        );
      } catch (error) {
        console.log(error);
        notify(errorMessageHandler(error), "error", {}, false, 100000);
      }
    },
    [update, notify, redirect]
  );

  const PostEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );

  return (
      <Edit {...props}>
        <SimpleForm onSubmit={save} toolbar={<PostEditToolbar />}>
            <Section>
                <SectionInputs>
                    <TextInput source="name" label={"Name"} />
                    <TextInput source="location" label={"Location"} />
                    <DateInput variant="outlined" source="takePlaceOn.datetime" label={"Take Place On"} />
                    <TimeInput variant="outlined" source="time.from" label={"Time From"} />
                    <TimeInput variant="outlined" source="time.to" label={"Time To"} />
                </SectionInputs>
            </Section>
        </SimpleForm>
      </Edit>
  );
};

export default withTitle(EventEdit, "Event Edit");
