import { useMemo, useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import Api from "../Api";

const useApi = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const api = useMemo(
    () => new Api(notify, translate, setIsSubmitting),
    [notify, translate]
  );

  return {
    api,
    isSubmitting: isSubmitting,
  };
};

export default useApi;
