import React, { useState } from "react";
import styled from "styled-components";
import CropIcon from "@mui/icons-material/Crop";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ButtonGroup, IconButton } from "@mui/material";
import { CropDialog } from "./crop-dialog";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { useNotify } from "react-admin";
import useApi from "../../hooks/useApi";
import { generateId } from "../../utils/uri";

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: scale-down;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(60, 60, 60);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 0.3;
    }

    & > div {
      opacity: 1;
    }
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  position: absolute;
  right: 4px;
  top: 4px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  & svg {
    color: white;
  }
`;

export const ImageItem = ({ image, saveImage }) => {
  const [cropOpen, setCropOpen] = useState(false);
  const notify = useNotify();
  const { api } = useApi();

  if (!image) return null;

  const onSaveCrop = (croppedImageBlob) => {
    return new Promise(async (resolve, reject) => {
      if (!croppedImageBlob) {
        reject("No image blob provided");
        return;
      }

      const reader = new FileReader();

      const readAsDataURL = new Promise((res, rej) => {
        reader.addEventListener("load", () => res(reader.result));
        reader.addEventListener("error", rej);
        reader.readAsDataURL(croppedImageBlob);
      });

      const result = await readAsDataURL;

      const imgLoad = new Promise((res) => {
        const imageObj = new Image();
        imageObj.addEventListener("load", res);
        imageObj.src = result;
      });

      await imgLoad;

      const formData = new FormData();
      const fileName = `${generateId()}.jpg`;
      formData.append("file", croppedImageBlob, fileName);

      try {
        const response = await api.post(
          getApiRoute(`images/${image.id}`),
          formData
        );
        saveImage(response.data);
        setCropOpen(false);
        notify(
          "Image was updated, wait few seconds till new image is uploaded."
        );
        resolve(response.data);
      } catch (e) {
        notify(errorMessageHandler(e), "error", {}, false, 100000);
        reject(e);
      }
    });
  };

  return (
    <ImageWrapper>
      <StyledButtonGroup>
        <IconButton onClick={() => setCropOpen(true)} size="large">
          <CropIcon />
        </IconButton>
        <IconButton onClick={() => saveImage()} size="large">
          <DeleteOutlineIcon />
        </IconButton>
      </StyledButtonGroup>

      <StyledImage src={image.absoluteUrl} alt={image.id} />

      <CropDialog
        open={cropOpen}
        onClose={() => setCropOpen(false)}
        fileToCrop={image.absoluteUrl}
        onSaveCrop={onSaveCrop}
      />
    </ImageWrapper>
  );
};
