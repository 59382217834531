import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileList from "./FileList";
import FileCreate from "./FileCreate";

const files = {
  label: "Files",
  list: FileList,
  create: FileCreate,
  icon: InsertDriveFileIcon,
};

export default files;
