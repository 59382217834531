import React, { useEffect, useState, useCallback } from "react";
import {
  TextField,
  FormControl,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import {useDataProvider, useNotify, Edit, useRecordContext} from "react-admin";
import { isValidEmail } from "../../utils/uri";
import useApi from "../../hooks/useApi";
import { useRedirect } from "react-admin";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from '../with-title'

const CustomFormControl = styled(FormControl)`
  margin: 0 0 32px 0 !important;
`;

const FormWrapper = styled.div`
  margin-top: 30px;
  padding: 20px;
`;

const ButtonSave = styled(Button)`
  background-color: #424242 !important;
  color: #ffffff;

  svg {
    color: #ffffff !important;
  }
`;

const ButtonDelete = styled(Button)`
  background-color: rgb(220, 0, 78) !important;
  color: #ffffff;
`;

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserEdit = ({ ...props }) => {
  const id = window.location.href.split("/").pop();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { api, isSubmitting } = useApi();
  const redirect = useRedirect();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const user = (
        await dataProvider.getOne("users", {
          id,
          pagination: {},
          sort: {},
          filter: {},
        }).catch((e) => {
        console.log(e);
        notify(errorMessageHandler(e), "error", {}, false, 100000);
      })
      ).data;

      setUser(user);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [dataProvider, id, notify]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      fetchUser();
    }

    return () => (mount = false);
  }, [fetchUser]);

  const action = {
    change: (name, value) => {
      setUser((prev) => {
        return { ...prev, [name]: value };
      });
    },
    save: async () => {
      const { email, plainPassword } = user;
      if (email === "") {
        setIsEmailError(true);
      } else if (!isValidEmail(email)) {
        setIsEmailValid(false);
      }

      if (plainPassword && plainPassword.length < 6) {
        setIsPasswordError(true);
      }

      if (email !== "" && isValidEmail(email)) {
        const payload = {
          email,
          plainPassword,
        };
        await api.updateUser(user.id, payload);
        fetchUser();
        redirect("list", "/users");
      }
    },
    delete: () => {
      api.deleteUser(user.id);
      redirect("list", "/users");
    },
  };

  const UserTitle = () => {
    const record = useRecordContext()
    return <span>Edit user #{record ? `${record.id}` : ''}</span>
  }

  return (
    <div>
      <h1>Edit user</h1>
      <Edit title={<UserTitle />} {...props}>
        <Divider />
        <FormWrapper>
          <CustomFormControl fullWidth>
            <TextField
              error={isEmailError || !isEmailValid}
              disabled={isSubmitting || isLoading}
              value={user?.email}
              fullWidth
              label="Email"
              name="email"
              variant="filled"
              helperText={
                !isEmailValid
                  ? "Email is not valid"
                  : isEmailError
                  ? "Required"
                  : "\u00A0"
              }
              onChange={(e) => {
                setIsEmailError(false);
                setIsEmailValid(true);
                return action.change(e.target.name, e.target.value);
              }}
            />
          </CustomFormControl>
          <CustomFormControl fullWidth>
            <TextField
              value={user?.plainPassword || ""}
              error={isPasswordError}
              fullWidth
              disabled={isSubmitting || isLoading}
              type="password"
              label="Password"
              name="plainPassword"
              variant="filled"
              helperText={
                isPasswordError
                  ? "This value is too short. It should have 6 characters or more."
                  : "\u00A0"
              }
              onChange={(e) => {
                setIsPasswordError(false);
                return action.change(e.target.name, e.target.value);
              }}
            />
          </CustomFormControl>
          <ButtonHolder>
            <ButtonSave
              variant="contained"
              disabled={isSubmitting || isLoading}
              startIcon={!isSubmitting && <SaveIcon />}
              onClick={() => action.save()}
            >
              {isSubmitting && <CircularProgress size={16} />}
              {!isSubmitting && "Save"}
            </ButtonSave>
            <ButtonDelete
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => action.delete()}
            >
              Delete
            </ButtonDelete>
          </ButtonHolder>
        </FormWrapper>
      </Edit>
    </div>
  );
};

export default withTitle(UserEdit, 'User Edit');
