import { httpClient } from "./httpClient";
import { getApiRoute } from "../config/routes";
import { getUrlParams } from "../utils/uri";

export const clearAuth = () => {
  const { status } = getUrlParams();

  if (!status && !window.location.href.includes("set-password")) {
    const returnUrl = localStorage.getItem("returnUrl");
    localStorage.clear();
    if (returnUrl) {
      localStorage.setItem("returnUrl", returnUrl);
    }
    window.location.href = "#/login";
  }
};

const authProvider = {
  login: async ({ username, password }) => {
    await httpClient(getApiRoute("login_check"), {
      method: "post",
      body: JSON.stringify({
        username,
        password,
      }),
    });
  
    await httpClient(getApiRoute("me")).then(({ json }) => {
      localStorage.setItem("roles", JSON.stringify(json.roles));
    });
  
    localStorage.setItem("authorized", "yes");
    return Promise.resolve();
  },
  
  logout: async () => {
    clearAuth();

    return Promise.resolve();
  },

  checkError: async (error) => {
    if (
      401 === error.status ||
      (error.response && error.response.status === 401)
    ) {
      clearAuth();
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkAuth: async () => {
    if (localStorage.getItem("authorized")) {
      const returnUrl = localStorage.getItem("returnUrl")
      if (returnUrl) {
        localStorage.removeItem("returnUrl")
        window.location.hash = returnUrl.replace('#', '');
      }

      return Promise.resolve();
    } else {
      const currentHash = window.location.hash;
      if (!currentHash.includes("#/login")) {
        const returnUrl = currentHash;
        localStorage.setItem("returnUrl", returnUrl);
      }
      clearAuth();
      return Promise.reject();
    }
  },
  
  getPermissions: () => {
    const roles = JSON.parse(localStorage.getItem("roles"));
    if (window.location.href.includes("set-password")) {
      return Promise.resolve([]); // make set-password page available without authentication
    }
    return Promise.resolve(roles);
  },
};

export default authProvider;
