import AccountIcon from "@mui/icons-material/AccountCircle";
import AccountList from "./AccountList";

const account = {
  label: "Account",
  list: AccountList,
  icon: AccountIcon,
};

export default account;
