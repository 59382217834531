import React, { useState } from "react";
import { ImageField, ImageInput, useNotify } from "react-admin";
import { CircularProgress } from "@mui/material";
import { getApiRoute } from "../../config/routes";
import useApi from "../../hooks/useApi";
import { ImageItem } from "../images-input/image-item";
import { MAX_IMAGE_SIZE_MB, compressImage, resizeImage } from "../../utils/images";
import { generateId } from "../../utils/uri";
import useFiles from "../../hooks/useFiles";

export const VariationImage = ({ image, setVariationsObject, variationId }) => {
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const { imageMimeTypes } = useFiles()

  const onChangeImage = async (fileOrEvent, variationId) => {
    if (!fileOrEvent) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
    let file;

    if (fileOrEvent instanceof Event) {
      file = fileOrEvent.target.files[0];
    } else if (fileOrEvent instanceof File) {
      file = fileOrEvent;
    }

    if (!file) {
      handleImageRemoval(variationId);
      return;
    }

    setLoading(true);

    const compressedBlob = await compressImage(file);

    if (compressedBlob.size > MAX_IMAGE_SIZE_MB * 1000000) {
      return notify(`File size exceeds ${MAX_IMAGE_SIZE_MB}MB`, "error");
    }

    uploadImage(compressedBlob, variationId);
  };

  const handleImageRemoval = (variationId) => {
    setVariationsObject((cur) => {
      const variation = cur[variationId];
      variation.image = null;
      return {
        ...cur,
        [variationId]: variation,
      };
    });
  };

  const uploadImage = async (file, variationId) => {
    const formData = new FormData();
    const fileName = `${generateId()}.jpg`;
    const resizedFile = await resizeImage(file)
    formData.append("file", resizedFile, fileName);
    await api
      .post(getApiRoute("images"), formData)
      .then((resp) => {
        setVariationsObject((cur) => {
          const variation = cur[variationId];
          variation.image = resp.data;
          return {
            ...cur,
            [variationId]: variation,
          };
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <p style={{ color: "gray" }}>Variation image</p>
      <ImageInput
        style={{
          display: image ? "none" : "block",
        }}
        multiple={false}
        onChange={(e) => onChangeImage(e, variationId)}
        source={`_${variationId}_image`}
        label=" "
        accept={imageMimeTypes}
        placeholder={
          <p>
            Drop a <b>single</b> picture to upload, or click to select it.
          </p>
        }
      >
        <ImageField source="absoluteUrl" style={{ display: "none" }} />
      </ImageInput>

      {loading ? (
        <CircularProgress />
      ) : (
        <ImageItem
          image={image}
          saveImage={(newImage) => {
            setVariationsObject((cur) => {
              const variation = cur[variationId];
              variation.image = newImage;
              return {
                ...cur,
                [variationId]: variation,
              };
            });
          }}
        />
      )}
    </>
  );
};
