import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import { useRecordContext } from "react-admin";
import useApi from "../../../hooks/useApi";
import { Tooltip } from "@mui/material";

export const EmailOnboardingButton = React.memo((props) => {
  const { api, isSubmitting } = useApi();
  const record = useRecordContext();

  const onResetClick = useCallback(
    (e) => {
      const id = record?.user ? record?.user?.id : record?.id;
      api.emailOnboarding(id);
      e.stopPropagation();
      e.preventDefault();
    },
    [record, api]
  );

  return (
    <Tooltip title='Send Onboarding email'>
      <Button onClickCapture={onResetClick} disabled={!record?.user || isSubmitting} {...props}>
        <EmailIcon />
        {props.onlyIcon && <span>Send&nbsp;Onboarding&nbsp;email</span>}
      </Button>
    </Tooltip>
  );
});
