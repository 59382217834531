import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList
} from "react-admin";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';

const EventList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        <div><b>Name:</b> {record.name}</div>
        <div><b>Location:</b> {record.location}</div>
        <div><b>Take Place On:</b> {record.takePlaceOn.formatted3}</div>
        <div><b>Time From:</b> {record.time.from}</div>
        <div><b>Time To:</b> {record.time.to}</div>
      </div>
    )
  }

  return (
    <List
      {...props}
      perPage={50}
      exporter={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => ''}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="name" label={"Name"} />
          <TextField source="location" label={"Location"} />
          <TextField source="takePlaceOn.formatted3" label={"Take Place On"} />
          <TextField source="time.from" label={"Time From"} />
          <TextField source="time.to" label={"Time To"} />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(EventList, 'Event List');
