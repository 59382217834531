import React, { createContext, useContext } from "react";

const imagesContext = createContext();

const ImagesProvider = ({ children, images, setImages }) => {
  return (
    <imagesContext.Provider value={{ images, setImages }}>
      {children}
    </imagesContext.Provider>
  );
};

const useImages = () => useContext(imagesContext);

export { useImages, ImagesProvider };
