import { AutocompleteInput } from "../app/OutlinedInputs";
import useApi from "../../hooks/useApi";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { getApiRoute } from "../../config/routes";
import { useInput, useRecordContext, useNotify } from "react-admin";
import { useState, useEffect } from "react";


export const CountryInput = ({ source, isCreate = false, onChange, ...props }) => {
    const record = useRecordContext()
    const [countries, setCountries] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [country, setCountry] = useState(isCreate ? 'NL' : '');
    const { api } = useApi();
    const notify = useNotify();

    const {
        field: { onChange: onChangeInput },
      } = useInput({ record, source, ...props })

    useEffect(() => {
      api
        .get(getApiRoute("settings/countries"))
        .then(({ data }) => {
          setCountries(data || []);
          setTimeout(() => {
            setIsLoaded(true)
          }, 1000);
        })
        .catch((e) => {
          notify(errorMessageHandler(e), "error", {}, false, 100000);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (countries.length && record && record.country) {
        setCountry(record.country.id || record.country)
      }
    }, [countries.length, record])

    useEffect(() => {
      if (countries.length && country !== null) {
        onChangeInput(country)
      }
    }, [countries.length, country, onChangeInput])

    if (!isLoaded) {
      return null
    }

    return (
      <AutocompleteInput
        source={source}
        label="Country"
        choices={countries}
        defaultValue={country || ''}
        onChange={(e) => {
          setCountry(e)
          onChange(e)
        }}
        {...props}
      /> 
    )
}