import React from 'react';
import { Helmet } from 'react-helmet';

const withTitle = (WrappedComponent, title) => {
  return props => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default withTitle;
